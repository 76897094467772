<template>
  <div class="container">
    <div class="box">
      <div class='title'>
        {{titleInfo}}
      </div>
    </div>
    <div class='meetTime'>
      <span>会议时间</span>
      <span>{{timeinfo}}</span>
    </div>
    <div class='end_meet'>
      会议已结束
    </div>
  </div>
</template>

<script>
  import {
    loadLocal,
    saveLocal,
    removeLocal,
    isArray,
    formatCode,
    getUrlDate,
    getDate
  } from '@/utils/tool'
  import {
    getLocalConferenceNo,
    setLocalConferenceNo,
    getDisplayName,
    setDisplayName,
    getLocalUserInfo,
    setMeetToken,
    getMeetToken,
  } from '@/utils/auth'
  import {
    joinConference,
    landingToken,
    landingConfirm,
  } from '@/api/meet'

  import JoinButton from '@/components/button/JoinButton'

  export default {
    data() {
      return {
        titleInfo: '',
        timeinfo: '',
      }
    },

    created() {
      this.getRouter()
    },

    methods: {
      async getRouter() {
        // let url = 'https://mopan-wechat.test.100url.cn/join?token=Gg0ir9mY6_96511fe5-0a61-46be-9fd5-5613f4e6e883'
        let url = window.location.href
        let paramsArr = getUrlDate(url)
        if (paramsArr.token) {
          setMeetToken(paramsArr.token)
        }
        try {
          const resData = await landingToken({
            token: getMeetToken()
          })
          if (resData.info) {
            this.titleInfo = resData.info.topic
            let startTime = new Date(resData.info.planStartTime)
            let endTime = new Date(resData.info.planEndTime)
            let ymd = getDate(startTime)
            let _endYmd = getDate(endTime)
            this.timeinfo = `${ymd} ${startTime.toTimeString().substr(0, 5)}~${getDate(endTime)!=getDate(startTime) ? _endYmd + ' ' : ''}${ endTime.toTimeString().substr(0,5)}`
          }
          if(!resData.success) {
            this.$toast(resData.error)
          }
        } catch (error) {
          //todo错误处理
          this.$toast(error)
        }
      },

    },

  }

</script>

<style scoped lang="less">
  .container {
    width: 100%;
    height: 100%;
    padding-top: 16px;
    margin: 0 auto;
    background-color: #fafafaFF;


    .box {
      position: relative;
      width: 100%;
      height: 200px;
      font-size: 32px;
      background: #FFFFFF;
      border-top: 1px solid #E5E5E5FF;
      border-bottom: 1px solid #E5E5E5FF;
      box-sizing: border-box;
      overflow: hidden;

      .title {
        width: 100%;
        height: 135px;
        width: 92%;
        height: 135px;
        overflow-y: scroll;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        word-break: break-all;
      }
    }

    .meetTime {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      border-top: 1px solid #E5E5E5FF;
      border-bottom: 1px solid #E5E5E5FF;
      height: 104px;
      background: #FFFFFF;
      padding: 30px;
      font-size: 32px;
      color: #333333;
    }

    .end_meet {
      font-size: 36px;
      color: #666666;
      text-align: center;
      margin-top: 215px;
    }
  }

</style>
