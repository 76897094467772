export const isMobile = () => {
  return /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    .test(window.navigator.userAgent)
}

export const isSafari = () => {
  return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
}

export const isWeChatBrowser = () => { //包含PC版微信浏览器
  //MicroMessenger/7.0.20.1781(0x6700143B) WindowsWechat
  //MicroMessenger/8.0.20(0x1800142e) NetType/WIFI Language/zh_CN miniProgram
  return /MicroMessenger/i.test(window.navigator.userAgent) && !/miniProgram/i.test(window.navigator.userAgent)
}


export const getIOSVersion = () => {
  var userAgent = navigator.userAgent;
  var regex = /os (\d+)_(\d+)_?(\d+)?/i;
  var matches = userAgent.match(regex);
  if (matches && matches.length > 1) {
    return parseFloat(matches[1] + '.' + matches[2] + (matches[3] ? matches['3'] : '0'));
  }
  return null;
}
